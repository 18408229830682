const Logo = ({ className = '' }) => (
  <svg
    version="1.1"
    preserveAspectRatio="xMinYMin meet"
    id="logo"
    viewBox="0 0 200 138"
    className={className}
  >
    <title>Home Chef</title>
    <path
      id="logotype"
      d="M17.4,110.8v9.6H6.6v-9.6H2v23.8h4.6v-9.4h10.8v9.4H22v-23.8H17.4L17.4,110.8z M122.6,110.6 c3.2,0,6.3,1.3,8.6,3.5l0.2,0.2l-3.4,3.4l-0.2-0.2c-1.4-1.4-3.2-2.1-5.2-2.1c-4.1,0-7.3,3.3-7.3,7.3c0,4.1,3.3,7.3,7.3,7.3 c1.9,0,3.8-0.8,5.2-2.1l0.2-0.2l3.4,3.4l-0.2,0.2c-2.3,2.3-5.3,3.5-8.6,3.5c-6.7,0-12.1-5.4-12.1-12.1 C110.4,116,115.9,110.6,122.6,110.6L122.6,110.6z M37.4,110.6c6.7,0,12.1,5.4,12.1,12.1c0,6.7-5.4,12.1-12.1,12.1 c-6.7,0-12.1-5.4-12.1-12.1C25.3,116,30.7,110.6,37.4,110.6L37.4,110.6z M37.4,115.3c-4.1,0-7.3,3.3-7.3,7.3c0,4.1,3.3,7.3,7.3,7.3 c4.1,0,7.3-3.3,7.3-7.3C44.7,118.6,41.5,115.3,37.4,115.3L37.4,115.3z M186.6,134.6v-9.1h8.6V121h-8.6v-6.1H198v-4.1h-16v23.8H186.6 L186.6,134.6z M165.9,130.5v-5.4h8.6v-4.5h-8.6v-5.7h11.6v-4.1h-16.2v23.8h16.5v-4.1H165.9L165.9,130.5z M151.2,110.8v9.6h-10.8 v-9.6h-4.6v23.8h4.6v-9.4h10.8v9.4h4.6v-23.8H151.2L151.2,110.8z M85.8,130.5v-5.4H95v-4.5h-9.2v-5.7h11.6v-4.1H81.1v23.8h16.5v-4.1 H85.8L85.8,130.5z M52.8,110.4v6.3v17.9h4.6v-13.1l6.8,7l6.8-7v13.1h4.6v-17.9v-6.3l-11.4,11.8L52.8,110.4L52.8,110.4z"
      fill="#009f32"
    />
    <path
      id="icon"
      d="M145.8,24.4L99.9,1l-45.6,23c-0.2,0.1-0.3,0.3-0.3,0.5v75.3l92,0V24.7C146,24.7,146,24.4,145.8,24.4z M141.7,95.6 l-25.6,0c-0.2-1.7-0.3-4.3-0.4-6.2c-0.5-8.4,0.5-29.8,0.3-62.4c0-1.1-1.3-1.6-2-0.8c-4.4,5.5-6.7,10.8-7.8,23.3 c-0.6,6.7,3.3,19.4,4.4,28.9c0.6,5.2,0.4,10.7-0.2,17.2l-17.6,0c-0.1-2-0.2-4-0.3-5.9c-0.4-7.9-0.7-15.9-1-23.8 c-0.1-1.5-0.2-3.7,0.6-5.1c0.3-0.5,0.7-1,1-1.5c0.6-0.9,1-2,1.4-3c0.9-2.7,1.6-5.7,1.7-8.6c0-0.1,0-0.1,0-0.1v-0.1 c0-0.2,0-0.4,0-0.7l0-0.1l-1.1-18.8c0-0.7-0.4-1.2-1-1.2c-0.6,0-1.2,0.6-1.2,1.3l-0.6,21.6c0,0.5-0.4,0.9-0.8,0.9 c-0.5,0-0.7-0.3-0.7-0.9l-0.5-21.8c0-0.3-0.4-1.1-1.2-1.1c-0.8,0-1,1-1,1.3l-0.8,21.5c0,0.5-0.4,0.9-0.8,0.9c-0.5,0-0.7-0.4-0.7-1 L85.5,28c0-0.7-0.6-1.3-1.2-1.3c-0.6,0-1,0.6-1,1.3L82,46.7l0,0.1c0,0.2,0,0.4,0,0.7v0.2c0.1,2.9,0.8,5.9,1.7,8.6 c0.4,1,0.8,2.1,1.4,3c0.3,0.5,0.7,1,1,1.5c0.8,1.4,0.7,3.5,0.6,5.1c-0.3,7.9-0.7,15.9-1,23.8c-0.1,2-0.2,4-0.3,5.9l-27.2,0V26.8 L99.9,5.8l41.8,21.3V95.6z"
      fill="#009f32"
    />
    <path
      id="trademark"
      d="M195.9,132.7h-0.8v-0.3h1.9v0.3h-0.8v2.1h-0.3V132.7z M197.5,132.4h0.4l0.9,1.3l0.9-1.3h0.4v2.4h-0.3V133 l-0.9,1.3h0l-0.9-1.3v1.9h-0.3V132.4z"
      fill="#009f32"
    />
  </svg>
);

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
