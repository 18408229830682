import cn from 'classnames';

import StepIndicator from 'components/common/StepIndicator';

const StepIndicatorWithButtons = ({
  isTempo = false,
  nextButtonWrapperClassName = '',
  prevButtonWrapperClassName = '',
  wrapperClassName = '',
  currentStep,
  lastStepButtonText,
  numSteps,
  onNextStep,
  onPreviousStep,
  stepIndicatorOnClick,
}) => {
  const getButtonText = () => {
    if (currentStep === numSteps - 1) return lastStepButtonText;
    return 'Next';
  };

  return (
    <div className={wrapperClassName || 'mt-auto'}>
      <div className="flex w-full items-center justify-between">
        <div className={prevButtonWrapperClassName}>
          <button
            className={cn('btn btnSecondary mr-4 w-auto', {
              'cursor-default opacity-0': currentStep === 0,
            })}
            disabled={currentStep === 0}
            onClick={onPreviousStep}
          >
            Back
          </button>
        </div>
        <StepIndicator
          currentStep={currentStep}
          numSteps={numSteps}
          stepOnClick={stepIndicatorOnClick}
          isTempo={isTempo}
        />
        <div className={nextButtonWrapperClassName}>
          <button className="btn btnPrimary ml-4 w-auto" onClick={onNextStep}>
            {getButtonText()}
          </button>
        </div>
      </div>
    </div>
  );
};

StepIndicatorWithButtons.propTypes = {
  currentStep: PropTypes.number.isRequired,
  numSteps: PropTypes.number.isRequired,
  stepIndicatorOnClick: PropTypes.func.isRequired,
  onPreviousStep: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  lastStepButtonText: PropTypes.string.isRequired,
  isTempo: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  prevButtonWrapperClassName: PropTypes.string,
  nextButtonWrapperClassName: PropTypes.string,
};

export default StepIndicatorWithButtons;
